import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NormalLetter from '../Letters/NormalLetter'
import Puntuacion from '../Puntuacion'

// TOP 0% - 96%
// LEFT 0% - 99%

function NormalBoard() {

    const navigate = useNavigate()
    const appState = useSelector(state => state.appState)

    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    const [selector, setSelector] = useState(0)
    const [penaltyPoints, setPenaltyPoints] = useState(0)
    const [huntedLetters, setHuntedLetters] = useState(0)

    const [endScore, setEndScore] = useState(0)
    const [endGame, setEndGame] = useState(false)

    const [arrayLetters, setArrayLetters] = useState([])

    const handleKeyPress = (event) => {
        arrayLetters.map((letter, index) => {
            if (letter === event.key) {
                let index = Math.floor(Math.random() * letters.length)
                setArrayLetters([letters[index]])
                setHuntedLetters(prev => prev + 1)
            } else {
                setPenaltyPoints(prev => prev + 1)
            }
        })
    }

    useEffect(() => {
        console.log(appState.app);
        if (endScore) {
            fetch(process.env.REACT_APP_API_INSERT_ENTRY, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${appState.app.token}`,
                    'Content-Type': 'applicaton/json',
                    'Accept': 'applicaton/json'
                },
                body: JSON.stringify({
                    [process.env.REACT_APP_USR_HUNTER_KEYOFMAGICKEY]: process.env.REACT_APP_USR_HUNTER_MAGICKEY,
                    'name': appState.user.name,
                    'score': endScore,
                    'game_type': 'classic'
                })
            })
                .then(response => response.json())
                .then(jsonResponse => {
                    console.clear()
                })
        }
    }, [endScore])

    useEffect(() => {
        let index = Math.floor(Math.random() * letters.length)
        setArrayLetters([letters[index]])
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [arrayLetters]);

    return (
        <>
            {endGame ?
                <div className='boardGame' style={{ backgroundColor: 'white', width: '90vw', height: '88vh' }}>
                    <center>
                        <div style={{ position: 'relative', marginTop: '21%', width: '250px' }}>
                            <h3 style={{ color: 'orange' }}>Stage Completed!</h3>
                            <h5>End Score:</h5>
                            <h5 style={{ fontWeight: '700' }}>{endScore.toFixed(2)}</h5>
                            <p style={{ color: 'gray', fontSize: '12px' }}>tiempo + fallos</p>
                            <p>Take a look at general Ranking to see if u reached any of the top10 position!</p>
                        </div>
                        <button onClick={() => navigate('/home')} className='btn btn-success' style={{ paddingLeft: '18px', paddingRight: '18px', fontWeight: '800' }}>OK</button>
                    </center>
                </div>
                :
                <>
                    <div className='boardGame' style={{ backgroundColor: 'white', width: '90vw', height: '88vh' }}>
                        {arrayLetters.map((letter, index) => (
                            <NormalLetter key={index} letter={letter} />
                        ))}

                    </div>
                    <Puntuacion penaltyPoints={penaltyPoints} huntedLetters={huntedLetters} setEndScore={setEndScore} setEndGame={setEndGame} />
                </>
            }
        </>
    )
}

export default NormalBoard
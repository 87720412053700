const initialState = {
  app: {
    token: ''
  },
  user: {
    name: ''
  },
  game: {
    stages: [
      {
        title: 'Classic Game',
        viewName: 'classic',
        description: 'Clasic game with static letters. You only need to hunt 23 letters to win. ¿How fast u can be?'

      },
      {
        title: 'Evolution Game',
        viewName: 'evolution',
        description: 'Evolucion game with falling down letters. Time is your enemy. ¿How many letters can you hunt?'
      }
    ]
  }
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return {
        ...state,
        user: { ...state.user, name: action.payload }
      }
    case 'SET_TOKEN':
      return {
        ...state,
        app: { ...state.app, token: action.payload }
      }
    default:
      return state
  }
}

export default appReducer;
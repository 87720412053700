export const setUserName = name => {
  return {
    type: 'SET_NAME',
    payload: name
  }
}

export const setToken = token => {
  return {
    type: 'SET_TOKEN',
    payload: token
  }
}

  // otras acciones aquí
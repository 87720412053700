import React, { useEffect, useState } from 'react'
import { useInterval } from '../Hooks/UseInterval'

function Puntuacion({ penaltyPoints, huntedLetters, setEndScore, setEndGame }) {

    const [tiempo, setTiempo] = useState(0)

    useInterval(() => {
        setTiempo(prev => prev + 0.01)
    }, 10)

    useEffect(() => {
        if (huntedLetters === 23) {
            setEndScore(tiempo + penaltyPoints)
            setEndGame(true)
        }
    }, [huntedLetters])

    return (
        <>
            <div className='bg-dark' style={{ color: 'white' }}>Tiempo: {tiempo.toFixed(2)} Puntuacion: {(tiempo + penaltyPoints).toFixed(2)} <span style={{ color: 'lightgreen' }} >Faltan: {23 - huntedLetters} letras</span></div>
        </>
    )
}

export default Puntuacion
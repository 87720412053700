import React from 'react'

function NormalLetter({ letter }) {

    // TOP 0% - 96%
    // LEFT 0% - 96%

    const MAX_TOP = 96
    const MAX_LEFT = 98
    
    const top = Math.floor(Math.random() * MAX_TOP)
    const left = Math.floor(Math.random() * MAX_LEFT)

    return (
        <>
            <span id='letra' style={{ position: 'relative', top: `${top}%`, left: `${left}%`, color: 'red', fontWeight: '600', fontSize: '19px' }}>{letter}</span>            
        </>
    )
}

export default NormalLetter
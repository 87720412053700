import React, { useState } from 'react'
import EvolutionBoard from '../Boards/EvolutionBoard'
import NormalBoard from '../Boards/NormalBoard'

function ClassicView({ type }) {

    const [ready, setReady] = useState(false)
    const showThis = {
        title: type === 'classic' ? 'Classic Mode' : 'Evolution Mode',
        board: type === 'classic' ? <NormalBoard /> : <EvolutionBoard />
    }

    return (
        <>
            <div className='' style={{ backgroundColor: 'black', width: '100vw', display: 'flex', height: '100vh', justifyContent: 'center', paddingTop: '25px' }}>
                <div className='container'>
                    <div className='row text-center'>
                        <h1 style={{ fontSize: '23px', color: 'orange' }}>{showThis.title}</h1>
                    </div>
                    <div className='row'>
                        {ready ?
                            showThis.board
                            :
                            <>
                                <div className='boardGame' style={{ backgroundColor: 'white', width: '90vw', height: '88vh' }}>
                                    <center>
                                        <button onClick={() => setReady(!ready)} className='btn btn-primary' style={{ position: 'relative', marginTop: '30%', width: '150px' }}>Start!</button>
                                    </center>
                                </div>                                
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClassicView
import React, { useEffect, useState } from 'react'
import { useInterval } from '../Hooks/UseInterval'

function PuntuacionEvolution({ penaltyPoints, huntedLetters, setEndScore, setEndGame, letterAlarm, velocity }) {

    const [tiempo, setTiempo] = useState(0)

    useInterval(() => {
        setTiempo(prev => prev + 0.01)
    }, 10)

    useEffect(() => {
        if (letterAlarm) {
            setEndScore(huntedLetters * 5 - tiempo * 2 - penaltyPoints * 15)
            setEndGame(true)
        }
    }, [letterAlarm])

    return (
        <>
            <div className='bg-dark' style={{ color: 'white', zIndex: 1 }}>Tiempo: {tiempo.toFixed(2)} Puntuacion: {(huntedLetters * 5 - tiempo * 2 - penaltyPoints * 15).toFixed(2)} <span style={{ color: 'lightgreen' }} >Llevas: {huntedLetters} letras</span> | Velocidad: {velocity}</div>
        </>
    )
}

export default PuntuacionEvolution
import React from 'react'

function SorryMobile() {
    return (
        <div className='' style={{ backgroundColor: 'black', width: '100vw', display: 'flex', height: '100vh', justifyContent: 'center' }}>
            <div className='container' style={{paddingTop:'50%'}}>
                <div className='row d-flex justify-content-center'>
                    <h5 style={{ color: 'orange', textAlign: 'center' }}>Lo sentimos mucho</h5>
                </div>
                <div className='row d-flex justify-content-center'>                    
                <h6 style={{ color: 'white', textAlign: 'center' }}>Juegassso solo disponible para PC</h6>
                </div>                
            </div>
        </div>


    )
}

export default SorryMobile
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EvolutionLetter from '../Letters/EvolutionLetter'
import NormalLetter from '../Letters/NormalLetter'
import Puntuacion from '../Puntuacion'
import PuntuacionEvolution from '../PuntuacionEvolution'

function EvolutionBoard() {

    const navigate = useNavigate()
    const appState = useSelector(state => state.appState)

    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    const [selector, setSelector] = useState(0)
    const [penaltyPoints, setPenaltyPoints] = useState(0)
    const [huntedLetters, setHuntedLetters] = useState(0)
    const [letterAlarm, setLetterAlarm] = useState(false)

    const [endScore, setEndScore] = useState(0)
    const [endGame, setEndGame] = useState(false)

    const [velocity, setVelocity] = useState(500)
    const MAX_LEFT = 98

    const [letter1, setLetter1] = useState({ letter: '', left: '' })
    const [letter2, setLetter2] = useState({ letter: '', left: '' })
    const [letter3, setLetter3] = useState({ letter: '', left: '' })
    const [letter4, setLetter4] = useState({ letter: '', left: '' })
    const [letter5, setLetter5] = useState({ letter: '', left: '' })

    const handleKeyPress = (event) => {

        if (letter1.letter === event.key) {
            setLetter1({
                letter: letters[Math.floor(Math.random() * letters.length)],
                left: Math.floor(Math.random() * MAX_LEFT)
            })
            setHuntedLetters(prev => prev + 1)
        } else if (letter2.letter === event.key) {
            setLetter2({
                letter: letters[Math.floor(Math.random() * letters.length)],
                left: Math.floor(Math.random() * MAX_LEFT)
            })
            setHuntedLetters(prev => prev + 1)
        } else if (letter3.letter === event.key) {
            setLetter3({
                letter: letters[Math.floor(Math.random() * letters.length)],
                left: Math.floor(Math.random() * MAX_LEFT)
            })
            setHuntedLetters(prev => prev + 1)
        } else if (letter4.letter === event.key) {
            setLetter4({
                letter: letters[Math.floor(Math.random() * letters.length)],
                left: Math.floor(Math.random() * MAX_LEFT)
            })
            setHuntedLetters(prev => prev + 1)
        } else if (letter5.letter === event.key) {
            setLetter5({
                letter: letters[Math.floor(Math.random() * letters.length)],
                left: Math.floor(Math.random() * MAX_LEFT)
            })
            setHuntedLetters(prev => prev + 1)
        } else {
            setPenaltyPoints(prev => prev + 1)
        }

        if (huntedLetters % 4 === 0) {

            setVelocity(prev => prev - 10)
        }

    }

    useEffect(() => {
        let indexs = []
        let lefts = []
        for (let i = 0; i < 5; i++) {
            indexs[i] = Math.floor(Math.random() * letters.length)
            lefts[i] = Math.floor(Math.random() * MAX_LEFT)
        }
        setLetter1({ letter: letters[indexs[0]], left: lefts[0] })
        setLetter2({ letter: letters[indexs[1]], left: lefts[1] })
        setLetter3({ letter: letters[indexs[2]], left: lefts[2] })
        setLetter4({ letter: letters[indexs[3]], left: lefts[3] })
        setLetter5({ letter: letters[indexs[4]], left: lefts[4] })

    }, [])

    useEffect(() => {
        console.log(appState.app);
        if (endScore) {
            fetch(process.env.REACT_APP_API_INSERT_ENTRY, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${appState.app.token}`,
                    'Content-Type': 'applicaton/json',
                    'Accept': 'applicaton/json'
                },
                body: JSON.stringify({
                    [process.env.REACT_APP_USR_HUNTER_KEYOFMAGICKEY]: process.env.REACT_APP_USR_HUNTER_MAGICKEY,
                    'name': appState.user.name,
                    'score': endScore,
                    'game_type': 'evolution'
                })
            })
                .then(response => response.json())
                .then(jsonResponse => {
                    console.clear()
                })
        }
    }, [endScore])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [letter1, letter2, letter3, letter4, letter5]);

    return (
        <>
            {endGame ?
                <div className='boardGame' style={{ backgroundColor: 'white', width: '90vw', height: '88vh' }}>
                    <center>
                        <div style={{ position: 'relative', marginTop: '21%', width: '250px' }}>
                            <h3 style={{ color: 'orange' }}>Stage Completed!</h3>
                            <h5>End Score:</h5>
                            <h5 style={{ fontWeight: '700' }}>{endScore.toFixed(2)}</h5>
                            <p style={{ color: 'gray', fontSize: '12px' }}>aciertos * 5 - tiempo * 2 - fallos * 15</p>
                            <p>Take a look at general Ranking to see if u reached any of the top10 position!</p>
                        </div>
                        <button onClick={() => navigate('/home')} className='btn btn-success' style={{ paddingLeft: '18px', paddingRight: '18px', fontWeight: '800' }}>OK</button>
                    </center>
                </div>
                :
                <>
                    <div className='boardGame' style={{ backgroundColor: 'white', width: '90vw', height: '88vh' }}>

                        <EvolutionLetter letter={letter1.letter} left={letter1.left} velocity={velocity} setLetterAlarm={setLetterAlarm} />
                        <EvolutionLetter letter={letter2.letter} left={letter2.left} velocity={velocity} setLetterAlarm={setLetterAlarm} />
                        <EvolutionLetter letter={letter3.letter} left={letter3.left} velocity={velocity} setLetterAlarm={setLetterAlarm} />
                        <EvolutionLetter letter={letter4.letter} left={letter4.left} velocity={velocity} setLetterAlarm={setLetterAlarm} />
                        <EvolutionLetter letter={letter5.letter} left={letter5.left} velocity={velocity} setLetterAlarm={setLetterAlarm} />

                    </div>
                    <PuntuacionEvolution penaltyPoints={penaltyPoints} huntedLetters={huntedLetters} setEndScore={setEndScore} setEndGame={setEndGame} letterAlarm={letterAlarm} velocity={velocity} />
                </>
            }
        </>
    )
}

export default EvolutionBoard

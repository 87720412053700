import React, { useEffect, useState } from 'react'
import { useInterval } from '../../Hooks/UseInterval'

function EvolutionLetter({ letter, left, velocity, setLetterAlarm }) {

    const [top, setTop] = useState(0)    

    useInterval(() => {
        setTop(prev => prev + 1)
        if (top === 98) {
            setLetterAlarm(true)
        }
    }, velocity)

    useEffect(() => {
        setTop(0)
    }, [letter])

    return (
        <>
            <span id='letra' style={{ position: 'relative', top: `${top}%`, left: `${left}%`, color: 'red', fontWeight: '600', fontSize: '19px' }}>{letter}</span>
        </>
    )
}

export default EvolutionLetter
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setToken, setUserName } from '../Redux/appActions'

function LoginView() {

    const [nickName, setNickName] = useState('')
    const [errorMessage, setMessage] = useState('')
    const [correctLogin, setCorrectLogin] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const appState = useSelector(state => state.appState)

    const handleClickBtnGo = () => {
        if (nickName.length < 5) {
            setMessage('at least 5 characters')
            setTimeout(() => {
                setMessage('')
            }, 1500);
        } else if (nickName.length > 13) {
            setMessage('13 characters limit')
            setTimeout(() => {
                setMessage('')
            }, 1500);
        } else {
            dispatch(setUserName(nickName))
            navigate('/home')
        }
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_LOGIN, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                [process.env.REACT_APP_USR_HUNTER_KEYOFMAGICKEY]: process.env.REACT_APP_USR_HUNTER_MAGICKEY,
                'email': process.env.REACT_APP_USR_HUNTER_SHORT,
                'password': process.env.REACT_APP_USR_HUNTER_LONG
            })
        })
            .then(response => response.json())
            .then(jsonResponse => {
                if (jsonResponse.status === 1) {
                    console.clear()
                    dispatch(setToken(jsonResponse.token))
                    setCorrectLogin(true)
                } else {
                    console.clear()
                }
            })
    }, [])

    return (
        <>
            <div className='' style={{ backgroundColor: 'black', width: '100vw', display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                {correctLogin ?
                    <div className='container'>
                        <div className='row d-flex justify-content-center'>
                            <h5 style={{ color: 'orange', textAlign: 'center' }}>Enter your name</h5>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <input onChange={(e) => setNickName(e.target.value)} value={nickName} className='form-control' type='text' style={{ width: '160px' }} />
                        </div>
                        <h6 className='text-center' style={{ color: 'tomato', height: '20px' }}>{errorMessage}</h6>
                        <div className='row d-flex justify-content-center'>
                            <button onClick={handleClickBtnGo} className='btn btn-primary mt-3' style={{ width: '80px' }} >GO!</button>
                        </div>
                    </div>
                    :
                    <>
                        <div className='row'>
                            <h4 style={{ color: 'white', textAlign: 'center' }}>waiting server...</h4>
                            <h6 style={{ color: 'orange', fontSize: '12px', textAlign: 'center' }}>Si éste mensaje persiste serán malas noticias bro!</h6>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default LoginView

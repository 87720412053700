import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function ClassicRanking({ type }) {

  const appState = useSelector(state => state.appState)
  const navigate = useNavigate()

  const [data, setData] = useState(null)

  useEffect(() => {
    fetch(type === 'classic' ? process.env.REACT_APP_API_CLASSICTABLE : process.env.REACT_APP_API_EVOLUTIONTABLE, {
      headers: {
        'Authorization': `Bearer ${appState.app.token}`,
        'Accept': 'application/json',
      }
    })
      .then(response => response.json())
      .then(jsonResponse => {
        if (jsonResponse.status === 1) {
          setData(jsonResponse.data)
        }
      })

  }, [])
  return (
    <>
      <div className='' style={{ backgroundColor: 'black', width: '100vw', display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            {data ?
              <div className='col-4'>
                <div className='card' style={{ backgroundColor: 'white', paddingTop: '12px', paddingBottom: '12px', border: '2px solid green', fontSize: '18px' }}>
                  <h5 style={{ color: 'black', textAlign: 'center', fontSize: '20px', fontWeight: '700', color: 'orangered' }}>{type === 'classic' ? 'Classic Ranking' : 'Evolution Ranking'}</h5>
                  <div className='card-body' >
                    {data.map((row, index) => (
                      <div key={index} className='row' style={{ backgroundColor: index % 2 === 0 ? 'whitesmoke' : null }}>
                        <div className='col-6'>
                          {index + 1})<span style={{ marginLeft: '3px', fontWeight: '700' }}>{row.name}</span>:
                        </div>
                        <div className='col'>
                          <span>{row.score} <span style={{ color: 'gray', fontSize: '14px' }}>points</span></span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <center>
                    <button onClick={() => navigate('/home')} className='btn btn-success' style={{ width: '150px' }}>
                      <div className='row'>
                        <div className='col-12'>
                          OK
                        </div>
                      </div>
                    </button>
                  </center>
                </div>
              </div>
              :
              null
            }
          </div>
        </div></div>
    </>
  )
}

export default ClassicRanking
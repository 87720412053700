import './App.css';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginView from './Components/LoginView';
import { useEffect, useState } from 'react';
import { screen } from '@testing-library/react';
import SorryMobile from './Components/SorryMobile';
import HomeView from './Components/HomeView';
import ClassicView from './Components/GameViews/ClassicView';
import EvolutionView from './Components/GameViews/EvolutionView';
import ClassicRanking from './Components/GameViews/ClassicRanking';
import EvolutionRanking from './Components/GameViews/EvolutionRanking';

function App() {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <Provider store={store}>

      <BrowserRouter>
        <Routes>
          {isMobile ?
            <Route path="*" element={<SorryMobile />} />
            :
            <>
              <Route path="/" element={<LoginView />} />
              <Route path="/home" element={<HomeView />} />
              <Route path='/classic' element={<ClassicView type={'classic'} />} />
              <Route path='/evolution' element={<ClassicView type={'evolution'} />} />
              <Route path='/classic_ranking' element={<ClassicRanking type={'classic'} />} />
              <Route path='/evolution_ranking' element={<ClassicRanking type={'evolution'} />} />
            </>
          }
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

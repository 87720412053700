import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Icon from '@mdi/react';
import { mdiPodiumGold, mdiCrosshairs } from '@mdi/js';

function HomeView() {

    const appState = useSelector(state => state.appState)
    const navigate = useNavigate()

    const handlePlayBtn = (view) => {
        navigate('/' + view)
    }

    const handleRankingBtn = (view) => {
        navigate('/' + view + '_ranking')
    }

    return (
        <>
            <div className='' style={{ backgroundColor: 'black', width: '100vw', display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <div className='container'>
                    
                    <div className='row d-flex justify-content-center'>
                    <div className='col-12'>
                    <h1 style={{color:'orange', textAlign:'center', marginBottom:'80px'}}>Letter Hunter</h1>
                    </div>
                        {appState.game.stages.map((stage, index) => (
                            <div className='col-4' key={index}>
                                <div className='card' style={{ backgroundColor: 'white', paddingTop: '12px', paddingBottom: '12px', border: '2px solid orange' }}>
                                    <h5 style={{ color: 'black', textAlign: 'center' }}>{stage.title}</h5>
                                    <div className='card-body' style={{ height: '100px' }}>
                                        <p style={{ color: 'black' }}>{stage.description}</p>
                                    </div>
                                    <center>
                                        <button onClick={() => handlePlayBtn(stage.viewName)} className='btn btn-primary' style={{ width: '150px' }}>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Icon path={mdiCrosshairs} size={1} />
                                                </div>
                                                <div className='col-12'>
                                                    Play
                                                </div>
                                            </div>
                                        </button>
                                        <button onClick={() => handleRankingBtn(stage.viewName)} className='btn btn-success' style={{ width: '150px', marginLeft: '5px' }}>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Icon path={mdiPodiumGold} size={1} />
                                                </div>
                                                <div className='col-12'>
                                                    Ranking
                                                </div>
                                            </div>
                                        </button>
                                    </center>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeView